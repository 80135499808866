.userProfile .credentialData {
  flex-basis: 100%;
  margin: 30px 0;
}

.credentialBreachProfile {
  .auth-content {
    .text-center {
      display: none;
      justify-content: space-between;
      align-items: center;
      // margin: 10px 0;
      margin-top: 10px;
      padding-bottom: 10px;
      .btn {
        margin-right: 10px !important;
      }
      // button
    }
  }
  .credentialTable {
    width: 100%;
    border: none !important;
    padding: 0 10px !important;
    thead tr td th {
      padding: 0 10px !important;
    }
    thead,
    tbody,
    tfoot,
    tr,
    td {
      border: 1px solid #e9e9ef;
    }
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      padding: 10px;
    }
  }
}

// sideHeaderBorder
.sideHeaderBorder {
  height: 1px;
  width: 100%;
  background-color: #e9e9ef;
  margin: 15px 0;
}

// modal CSS
.modal {
  left: -150px;

  .modal-content {
    width: 800px;
  }
}

#pageDropDown {
  background-color: #5156be !important;
}

.dropdown-toggle::after {
  color: #5156be !important;
}

