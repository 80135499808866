.header-profile-user {
  background-color: transparent;
  padding: 0px;
}

.header-item {
  margin-left: 10px;
}

.table-nowrap #IP {
  color: blue !important;
  white-space: nowrap;
}

.subdomainPortScanCardHead {
  display: flex;
  padding: 17px 20px !important;
  justify-content: space-between;
}

.subdomainPortScanCardBody {
  height: 300px !important;
  overflow: auto !important;
}

.mrRight {
  margin-right: 10px !important;
}

/* width */
.subdomainPortScanCardBody::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.subdomainPortScanCardBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.subdomainPortScanCardBody::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
.subdomainPortScanCardBody::-webkit-scrollbar-thumb:hover {
  background: rgb(202, 202, 202);
}

:-webkit-any(table, thead, tbody, tfoot, tr)>form {
  display: block !important;
}


.addUserForm {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
}

.addUserForm .string,
.addUserForm .addRisk {
  width: 90%;
  margin: 0 15px;
}

.userProfile {
  .auth-content {
    margin-top: 0 !important;
  }
}

#riskColorRed {
  color: red;

}

#setGreen {
  color: green;
}

#setYellow {
  color: yellow;
}

#setRed {
  color: red;
}

#setOrange {
  color: orange;
}

#setGrey {
  color: grey;
}

.ScanProfileHeading {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px !important;
}

.modal-body {
  overflow: auto;
  max-height: 500px;
}

.textWhiteCard {
  color: white !important;
}

.upper_div {
  width: 100%;
  height: 450px;
  background-color: #5156be;
  position: relative;
  // display: flex;
  // justify-content: end;

}

.upper_div svg {
  margin-top: 291px;
  width: 100%;
}

.mid_div {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.lower_div {
  width: 100%;
  // height: 50vh;
  background-color: white;
}

.login_card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.login_card {
  transition: all 0.6s;
  animation: flip 1s;
  // transform-style: preserve-3d;
}

@keyframes flip {
  0% {
    transform: rotateY(180deg);
  }

}

.login_card_row {
  // margin-top: -330px;
  justify-content: center;
  z-index: 2;
  background-color: #5156be;
  height: 100%;
}

.login_card_row .card {
  z-index: 2;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 7px;
}

.siteInfo {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  height: 100%;

  .mainHeader {
    display: flex;
    // justify-content: center;
    align-items: center;

    .logo {
      padding-right: 30px;
    }

    .title {
      font-weight: 800;
      font-size: 1.4rem;
    }

    margin-bottom: 10px;
  }

  .header {
    .heading {
      font-weight: 700;
      font-size: 14px;
      color: grey;
      margin-bottom: 5px;
    }

    .title {
      font-size: 15px;
      font-weight: 700;
      color: black;
    }

    margin-bottom: 20px;
  }

}

.assetInventoryCards {
  cursor: pointer;

  &:hover {
    // background-color: #5156be !important;
    color: red;
    .button {
      display: block;
      filter: brightness(100%);
    }
    .cards{
      transition: all .8s;
      filter: brightness(75%);
    }
  }

  .button {
    position: absolute;
    display: none;
    filter: brightness(100%);

    button {
      position: absolute;
      left: 32%; 
      // top: 53%;
      // // translate: ;
      // transform: translate(-53% , -53%) ;
      filter: brightness(100%);
    }
  }
}

#sidebar-menu {
  position: fixed;
}

// spinner 
$color: rgb(0, 92, 132);

.loader-spinner {
  width: 150px;
  height: 150px;

  svg {
    width: 90%;
    fill: none;
  }
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 0.7 0.3;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: $color;

  &.one {
    animation: load 1.5s infinite ease-in;
    animation-direction: reverse;
  }

  &.two {
    fill: $color;
  }

  &.three {
    animation: load 1.5s infinite;
  }
}

@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 990px) {

  .searchBar1 {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .editFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-bottom: 10px;
  }
}